import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';

const GET_MAINTENANCE_REQUEST = gql`
  query GetMaintenanceRequest($id: ID!) {
    maintenanceRequest(id: $id) {
      id
      title
      description
      status
      priority
    }
  }
`;

const UPDATE_MAINTENANCE_REQUEST = gql`
  mutation UpdateMaintenanceRequest($id: ID!, $title: String!, $description: String!, $status: String!, $priority: String!) {
    updateMaintenanceRequest(id: $id, title: $title, description: $description, status: $status, priority: $priority) {
      id
      title
      description
      status
      priority
    }
  }
`;

const EditMaintenanceRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading: queryLoading, error: queryError } = useQuery(GET_MAINTENANCE_REQUEST, {
    variables: { id },
  });
  const [updateMaintenanceRequest, { error: mutationError }] = useMutation(UPDATE_MAINTENANCE_REQUEST, {
    onCompleted: () => {
      navigate('/dash/maintenance'); // Redirect after update
    },
    onError: (error) => {
      console.error('Error updating maintenance request:', error.message);
    }
  });

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('Active');
  const [priority, setPriority] = useState('Normal');

  useEffect(() => {
    if (data && data.maintenanceRequest) {
      setTitle(data.maintenanceRequest.title);
      setDescription(data.maintenanceRequest.description);
      setStatus(data.maintenanceRequest.status);
      setPriority(data.maintenanceRequest.priority);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateMaintenanceRequest({ variables: { id, title, description, status, priority } });
    } catch (error) {
      console.error('Error updating maintenance request:', error.message);
    }
  };

  const handleBack = () => {
    navigate('/dash/maintenance');
  };

  if (queryLoading) return <p>Loading...</p>;
  if (queryError) return <p>Error: {queryError.message}</p>;

  return (
    <div className="p-4 max-w-lg mx-auto">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="flex items-center text-gray-600 hover:text-gray-800">
          <span className="text-xl mr-2">←</span>
          <span>Back to Maintenance List</span>
        </button>
      </div>
      <h2 className="text-2xl font-bold mb-4">Edit Maintenance Request</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="Active">Active</option>
          <option value="In-Progress">In-Progress</option>
          <option value="Completed">Completed</option>
        </select>
        <select
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="Low">Low</option>
          <option value="Normal">Normal</option>
          <option value="High">High</option>
        </select>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Update Request
        </button>
        {mutationError && (
          <div className="mt-4 text-red-600">
            Error updating maintenance request: {mutationError.message}
          </div>
        )}
      </form>
    </div>
  );
};

export default EditMaintenanceRequest;
