import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const GET_BILLS = gql`
  query {
    bills {
      id
      tenantName
      houseno
      amountDue
      
    }
  }
`;

const BillsList = () => {
  const { loading, error, data } = useQuery(GET_BILLS);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredBills = data?.bills.filter((bill) =>
    bill.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bill.tenantHouseno.includes(searchTerm)
  ) || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBills = filteredBills.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredBills.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) return <p><ClipLoader color="#4A90E2" size={50} /></p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        
        

        <section className="bg-white p-6 rounded-lg shadow-md">

          <input
            type="text"
            className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            placeholder="Search by tenant name or house number"
            value={searchTerm}
            onChange={handleSearch}
          />

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Tenant</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">House No</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Bill Amount (KSH)</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium" colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentBills.map((bill) => (
                  <tr key={bill.id} className="border-t">
                    <td className="py-2 px-4 text-center">{bill.tenantName}</td>
                    <td className="py-2 px-4 text-center">{bill.houseno}</td>
                    <td className="py-2 px-4 text-center">{bill.amountDue.toFixed(2)} KSH</td>
                    <td className="py-2 px-4 text-center">
                      <Link to={`/viewbill/${bill.id}`} className="text-blue-600 hover:underline">View</Link>
                    </td>
                    <td className="py-2 px-4 text-center">
                    <Link to={`/editbill/${bill.id}`} className="text-yellow-600 hover:underline">Edit</Link>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              className="px-4 py-2 mx-1 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 mx-1">{currentPage} / {totalPages}</span>
            <button
              className="px-4 py-2 mx-1 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BillsList;
