import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tenant from '../Tenants/TenantList';
import Bills from '../Bills/BillList';
import EventsList from '../Events/EventsList'; // Import the EventsList component
import { FaHome, FaFileAlt, FaDollarSign, FaTools, FaCalendarAlt, FaClock, FaBars, FaTimes, FaChartBar } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Maintenance from '../maintenance/MaintenanceList';
import { useQuery, gql } from '@apollo/client';

const GET_EVENTS = gql`
  query {
    getEvents {
      id
      title
      date
      time
      about
    }
  }
`;

const Dashboard = () => {
  const { section } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  
  const { loading, error, data } = useQuery(GET_EVENTS);

  useEffect(() => {
    if (section) {
      setActiveSection(section);
    }
  }, [section]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setIsMenuOpen(false);
  };

  // Function to parse Unix timestamp and format the date
  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10)); // Parse the timestamp as an integer
    return date.toLocaleDateString('en-US'); // Format to a readable date
  };

  return (
    <div className="relative min-h-screen bg-gray-50">
      <button
        onClick={toggleMenu}
        className="fixed top-4 left-4 z-50 justify-end text-3xl text-gray-700 bg-white p-3 rounded-full shadow-md hover:bg-gray-200 transition-all"
      >
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>

      {isMenuOpen && (
        <motion.div
          className="fixed top-0 left-0 w-64 bg-gray-800 text-white h-full z-40 shadow-lg"
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <nav className="p-4">
            <ul>
              <li className='mt-10'>
                <button
                  onClick={() => handleSectionChange('dashboard')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaHome className="w-6 h-6 mr-3" />
                  <span>Home</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('tenant-info')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaFileAlt className="w-6 h-6 mr-3" />
                  <span>Tenant Info</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('bills')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaDollarSign className="w-6 h-6 mr-3" />
                  <span>Bills</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('maintenance')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaTools className="w-6 h-6 mr-3" />
                  <span>Maintenance</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('events')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaCalendarAlt className="w-6 h-6 mr-3" />
                  <span>Events</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('reports')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaChartBar className="w-6 h-6 mr-3" />
                  <span>Reports</span>
                </button>
              </li>
            </ul>
          </nav>
        </motion.div>
      )}

      {!isMenuOpen && (
        <div className={`p-8`}>
          {activeSection === 'dashboard' && (
            <>
              <h1 className="text-3xl font-bold text-blue-900 mb-6">Welcome to Arcade Apartments</h1>
              <p className="text-gray-700 mb-6">Explore the dashboard to monitor tenant management, bills, and maintenance.</p>

              {/* Upcoming Events Section */}
              <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                <h2 className="text-2xl font-semibold mb-4 text-center">Upcoming Events</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {loading ? (
                    <p>Loading events...</p>
                  ) : error ? (
                    <p>Error loading events: {error.message}</p>
                  ) : (
                    data.getEvents.map((event) => (
                      <motion.div
                        key={event.id}
                        className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow hover:shadow-xl transition-all border border-gray-300"
                        whileHover={{ scale: 1.05 }}
                      >
                        <div className="flex items-center mb-3">
                          <h3 className="text-lg font-bold text-gray-800">{event.title}</h3>
                        </div>
                        <div className="text-center">
                          <p className="text-gray-600 mb-1">
                            <FaCalendarAlt className="inline-block text-blue-500 mr-1" />
                            {formatDate(event.date)}
                          </p>
                          <p className="text-gray-600">
                            <FaClock className="inline-block text-gray-500 mr-1" />
                            {event.time}
                          </p>
                        </div>
                      </motion.div>
                    ))
                  )}
                </div>
              </div>
            </>
          )}

          {/* Tenant Info Section */}
          {activeSection === 'tenant-info' && <Tenant />}
          {/* Bills Section */}
          {activeSection === 'bills' && <Bills />}
          {/* Maintenance Section */}
          {activeSection === 'maintenance' && <Maintenance />}
          {/* Events Section */}
          {activeSection === 'events' && <EventsList />}
          {/* Reports Section */}
          {activeSection === 'reports' && (
            <>
              <h2 className="text-2xl font-bold mb-6">Reports</h2>
              <p className="text-gray-700">Reports will be available soon.</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
