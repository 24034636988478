import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

const GET_BILL = gql`
  query GetBill($id: ID!) {
    bill(id: $id) {
      id
      tenantName
      houseno
      amountDue
      description
    }
  }
`;

const UPDATE_BILL = gql`
  mutation UpdateBill($id: ID!, $billAmount: Float!, $about: String!) {
    updateBill(id: $id, billAmount: $billAmount, about: $about) {
      id
      amountDue
      description
    }
  }
`;

const EditBill = () => {
  const { id } = useParams(); // Get the bill ID from URL parameters
  const [amountDue, setAmountDue] = useState('');
  const [description, setDescription] = useState('');
  const [updateBill, { error: mutationError }] = useMutation(UPDATE_BILL);
  
  const { data: billData, loading: billLoading, error: billError } = useQuery(GET_BILL, {
    variables: { id },
    skip: !id // Skip this query if there's no ID
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    if (billData) {
      setAmountDue(billData.bill.amountDue.toFixed(2));
      setDescription(billData.bill.description || '');
    }
  }, [billData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await updateBill({ 
        variables: { 
          id, 
          billAmount: parseFloat(amountDue), 
          about: description // Changed to match the server's expected parameter
        } 
      });
      console.log('Mutation Response:', response);
      navigate('/dash/bills');
    } catch (err) {
      console.error('Error updating bill:', err);
      if (err.networkError) {
        console.error('Network error details:', err.networkError.result.errors);
      }
    }
  };

  const handleBack = () => {
    navigate('/dash/bills');
  };

  if (billLoading) return <p>Loading...</p>;
  if (billError) return <p>Error loading bill: {billError.message}</p>;

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        <section className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <button onClick={handleBack} className="flex items-center text-gray-600 hover:text-gray-800">
              <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
              <span>Return to Bills</span>
            </button>
          </div>
          <h2 className="text-2xl font-semibold mb-4">Edit Bill</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
              <label htmlFor="tenantName" className="block text-gray-700">Tenant</label>
              <input
                type="text"
                id="tenantName"
                value={billData?.bill?.tenantName || ''}
                readOnly
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="houseno" className="block text-gray-700">House No</label>
              <input
                type="text"
                id="houseno"
                value={billData?.bill?.houseno || ''}
                readOnly
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="amountDue" className="block text-gray-700">Bill Amount (KSH)</label>
              <input
                type="number"
                id="amountDue"
                value={amountDue}
                onChange={(e) => setAmountDue(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="description" className="block text-gray-700">Description/About</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                rows="4"
              />
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Update Bill
            </button>

            {mutationError && <p className="text-red-600 mt-2">Error: {mutationError.message}</p>}
          </form>
        </section>
      </div>
    </div>
  );
};

export default EditBill;
