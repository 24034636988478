import Navbar from '../../components/navbar';
import Coverbanner from '../../components/coverbanner';
import Content from '../../components/content';
import Footer from '../../components/footer';
import WhyChooseUs from '../../components/whychooseus';
import BookAppointment from '../../components/book';


function Home() {
  return (
    <div className="App">
      <Navbar/>
      <Coverbanner/>
      <Content/>
      <WhyChooseUs/>
      <BookAppointment/>
      <Footer/>
    </div>
  );
}

export default Home;
