import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';

const DELETE_EVENT = gql`
    mutation DeleteEvent($id: ID!) {
        deleteEvent(id: $id)
    }
`;

const DeleteEvent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deleteEvent] = useMutation(DELETE_EVENT, {
        onCompleted: () => {
            console.log('Delete successful');
            navigate('/dash/events'); // Change to your redirect path
        },
        onError: (error) => {
            console.error('Error deleting event:', error.message);
        },
    });

    const handleDelete = () => {
        deleteEvent({ variables: { id } });
    };

    return (
        <div className="flex flex-col items-center justify-center p-6 max-w-md mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Delete Event</h2>
            <p className="mb-6">Are you sure you want to delete this event?</p>
            <div className="flex space-x-4">
                <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    Delete Event
                </button>
                <button
                    onClick={() => navigate('/DASH')}
                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default DeleteEvent;
