import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const GET_EVENTS = gql`
  query GetEvents {
    getEvents {
      id
      title
      date
      time
      about
    }
  }
`;

const EventsList = () => {
  const { loading, error, data } = useQuery(GET_EVENTS);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredEvents = data?.getEvents.filter((event) =>
    event.title.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEvents = filteredEvents.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredEvents.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) return <p><ClipLoader color="#4A90E2" size={50} /></p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        
        {/* Create Event Button */}
        <div className="mb-4 text-right">
          <Link to="/create-event">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Create New Event
            </button>
          </Link>
        </div>

        <section className="bg-white p-6 rounded-lg shadow-md">
          <input
            type="text"
            className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            placeholder="Search by title"
            value={searchTerm}
            onChange={handleSearch}
          />

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Title</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Date</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Time</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium" colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentEvents.map((event) => (
                  <tr key={event.id} className="border-t">
                    <td className="py-2 px-4 text-center">{event.title}</td>
                    <td className="py-2 px-4 text-center">
                      {new Date(Number(event.date)).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4 text-center">{event.time}</td>
                    <td className="py-2 px-4 text-center">
                      <Link to={`/view-event/${event.id}`} className="text-blue-600 hover:underline">
                        View
                      </Link>
                    </td>
                    <td className="py-2 px-4 text-center">
                      <Link to={`/delete-event/${event.id}`} className="text-red-600 hover:underline">
                        Delete
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              className="px-4 py-2 mx-1 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 mx-1">{currentPage} / {totalPages}</span>
            <button
              className="px-4 py-2 mx-1 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EventsList;
