import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { GET_MAINTENANCE_REQUESTS } from './MaintenanceList'; // Ensure this path is correct

const DELETE_MAINTENANCE_REQUEST = gql`
  mutation DeleteMaintenanceRequest($id: ID!) {
    deleteMaintenanceRequest(id: $id)
  }
`;

const DeleteMaintenance = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteMaintenanceRequest] = useMutation(DELETE_MAINTENANCE_REQUEST, {
    update(cache) {
      // Read the existing maintenance requests from the cache
      const { maintenanceRequests } = cache.readQuery({ query: GET_MAINTENANCE_REQUESTS }) || { maintenanceRequests: [] };
      // Filter out the deleted maintenance request
      cache.writeQuery({
        query: GET_MAINTENANCE_REQUESTS,
        data: {
          maintenanceRequests: maintenanceRequests.filter(request => request.id !== id),
        },
      });
    },
    onCompleted: () => {
      console.log('Delete successful');
      navigate('/maintenance-list'); // Redirect to the maintenance list page after deletion
    },
    onError: (error) => {
      console.error('Error deleting maintenance request:', error.message);
    }
  });

  const handleDelete = () => {
    deleteMaintenanceRequest({ variables: { id } });
  };

  return (
    <div className="p-4 max-w-lg mx-auto text-center">
      <h2 className="text-2xl font-bold mb-4">Delete Maintenance Request</h2>
      <p className="mb-4">Are you sure you want to delete this maintenance request?</p>
      <button
        onClick={handleDelete}
        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Delete
      </button>
      <button
        onClick={() => navigate('/maintenance-list')}
        className="ml-4 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
      >
        Cancel
      </button>
    </div>
  );
};

export default DeleteMaintenance;
