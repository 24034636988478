import React, { useState } from 'react'; // Importing React and useState
import { gql, useMutation } from '@apollo/client'; // Importing gql and useMutation from Apollo Client
import { useNavigate } from 'react-router-dom'; // Importing useNavigate from react-router-dom

// Define the GraphQL mutation for adding an event
const ADD_EVENT = gql`
  mutation AddEvent($title: String!, $date: String!, $time: String!, $about: String!) {
    addEvent(title: $title, date: $date, time: $time, about: $about) {
      id
      title
      date
      time
      about
    }
  }
`;

// CreateEvent component
const CreateEvent = () => {
  // Define state variables
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [about, setAbout] = useState('');
  const navigate = useNavigate(); // Initialize navigate for routing
  const [addEvent, { error: mutationError }] = useMutation(ADD_EVENT); // Use the mutation

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      // Send the mutation with the input values
      await addEvent({
        variables: { title, date, time, about },
      });
      navigate('/dash/events'); // Redirect to the dashboard after successful mutation
    } catch (err) {
      console.error('Error adding event:', err);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Create Event</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
              <label htmlFor="title" className="block text-gray-700">Event Title</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                required
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="date" className="block text-gray-700">Date</label>
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="time" className="block text-gray-700">Time</label>
              <input
                type="time"
                id="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="about" className="block text-gray-700">About</label>
              <input
                type="text"
                id="about"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                placeholder="Description"
                required
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Add Event
            </button>

            {mutationError && <p className="text-red-600 mt-2">Error: {mutationError.message}</p>}
          </form>
        </section>
      </div>
    </div>
  );
};

export default CreateEvent;
