import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="bg-gray-200 text-gray-800 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo and Description */}
          <div>
            <h2 className="text-xl font-bold">
              <span className="text-blue-900">NestFin</span>
              <span className="text-yellow-900">derHub</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Your trusted partner in property management solutions. We offer comprehensive services tailored to your needs.
            </p>
            <div className="mt-6 flex space-x-4">
              <a href="#xx" className="text-gray-600 hover:text-gray-800">
                <span className="sr-only">Twitter</span>
                {/* Twitter SVG Icon */}
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M23.643 4.937a9.654 9.654 0 01-2.828.775 4.93 4.93 0 002.163-2.723 9.77 9.77 0 01-3.125 1.195 4.916 4.916 0 00-8.482 4.482A13.942 13.942 0 011.675 3.149 4.913 4.913 0 003.195 9.72a4.898 4.898 0 01-2.228-.616v.063a4.914 4.914 0 003.946 4.814 4.899 4.899 0 01-2.224.085 4.923 4.923 0 004.6 3.417 9.868 9.868 0 01-6.102 2.104c-.396 0-.786-.023-1.174-.067a13.945 13.945 0 007.548 2.213c9.057 0 14.01-7.506 14.01-14.01 0-.213-.005-.425-.014-.636a9.998 9.998 0 002.457-2.55z" />
                </svg>
              </a>
              <a href="#xx" className="text-gray-600 hover:text-gray-800">
                <span className="sr-only">LinkedIn</span>
                {/* LinkedIn SVG Icon */}
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M4.98 3.5C4.98 2.121 3.86 1 2.49 1 1.119 1 0 2.121 0 3.5c0 1.378 1.12 2.5 2.49 2.5 1.37 0 2.49-1.122 2.49-2.5zM.34 8.732h4.951V24H.34V8.732zM15.675 8.305c-2.54 0-3.684 1.393-4.315 2.37v-.034H7.138V24h4.951V15.57c0-2.263.43-4.458 3.229-4.458 2.76 0 2.781 2.586 2.781 4.586V24h4.951v-8.992c0-5.506-2.948-7.703-6.375-7.703z"
                  />
                </svg>
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-700 uppercase tracking-wider">Quick Links</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="#xx" className="text-gray-600 hover:text-gray-800">Home</a></li>
              <li><a href="#xx" className="text-gray-600 hover:text-gray-800">About Us</a></li>
              <li><a href="#xx" className="text-gray-600 hover:text-gray-800">Services</a></li>
              <li><a href="#xx" className="text-gray-600 hover:text-gray-800">Contact</a></li>
              <li><a href="#xx" className="text-gray-600 hover:text-gray-800">FAQ</a></li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-sm font-semibold text-gray-700 uppercase tracking-wider">Contact Us</h3>
            <ul className="mt-4 space-y-2">
              <li className="text-gray-600">Email: nestfind@nestfinderhub.africa</li>
              <li className="text-gray-600">Phone: +254 718908962</li>
              <li className="text-gray-600">Address: 00100 ,Nairobi, Kenya</li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t border-gray-300 pt-6">
          <p className="text-gray-600 text-center">
            &copy; {new Date().getFullYear()} <span className="text-blue-900">Nest</span><span className="text-yellow-900">Finder</span>. All rights reserved.
          </p>
          <p className="text-gray-600 text-center mt-2">
            Created by <a href="https://www.linkedin.com/in/tony-wekesa-6689541b1" className="text-blue-900 hover:underline">@Tk</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
