import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';

const GET_TENANT = gql`
  query GetTenant($id: ID!) {
    tenant(id: $id) {
      id
      name
      email
      phone
      houseno
    }
  }
`;

const ViewTenant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_TENANT, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { tenant } = data;

  const handleReturn = () => {
    navigate('/dash/tenant-info'); // Navigate to /dash under tenants
  };

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center mb-8">
          {/* Return button using Unicode arrow */}
          <button onClick={handleReturn} className="flex items-center text-gray-600 hover:text-gray-800">
            <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
            <span>Return to Tenants</span>
          </button>
        </div>
        <h1 className="text-3xl font-semibold text-gray-800 mb-8">Tenant Details</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <p><strong>Name:</strong> {tenant.name}</p>
          <p><strong>Email:</strong> {tenant.email}</p>
          <p><strong>Phone:</strong> {tenant.phone}</p>
          <p><strong>House No:</strong> {tenant.houseno}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewTenant;
